let nextRoute = ``

exports.onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname
}

window.addEventListener("unhandledrejection", event => {
   if (/loading chunk \d* failed./i.test(event.reason)) {
        if (nextRoute) {
          window.location.pathname = nextRoute
       }
    }
});